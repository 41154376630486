export const cards = [
  {
    name: 'Waterfront Hotel',
    location: 'Balitmore, MD',
    date: '2024-08-10T21:30:00',
    link: 'https://maps.app.goo.gl/4P2Bvgi5fREt5PSE8',
    id: 1,
  },
  {
    name: 'Private Event',
    location: 'Balitmore, MD',
    date: '2024-08-31T21:00:00',
    id: 3,
  },
  {
    name: 'Waterfront Hotel',
    location: 'Balitmore, MD',
    date: '2024-10-25T21:30:00',
    link: 'https://maps.app.goo.gl/4P2Bvgi5fREt5PSE8',
    id: 2,
  },
];
